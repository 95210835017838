import { Link } from 'gatsby'
import Image from './image'
import PropTypes from 'prop-types'
import React from 'react'
import headerStyles from './header.module.css'

const Header = ({ siteTitle }) => (
  <header class={headerStyles.header}>
    <h1 class={headerStyles.headerLink}>
      <Link to="/" activeClassName={headerStyles.banner}>
        {siteTitle}
      </Link>
    </h1>
    <Image />
    <div class={headerStyles.description}>
      This Has Always Been, hosted by Emily and Lori! This has always been a
      podcast, anyone who tells you differently is lying.
      <div class={headerStyles.contact}>
        Email us at thishasalwaysbeen@gmail.com
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
